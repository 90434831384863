































































































































import { Component, Vue, Prop, Emit, Watch } from 'vue-property-decorator';
import { Inject } from '@cds/common';
import { I18nService } from '@cds/i18n';
import moment from 'moment';
//引入组件
import VueUeditorWrap from "vue-ueditor-wrap"; //es6
import { WechatImageTextService } from '@/services/wechat-imagetext-service';
import { WechatSendMessageGroupService } from '@/services/wechat-send-messages-group-service';
import { massSet } from '@/services/massage-service';
// import face from '@/components/face/face.vue';
import wechatImageText from '@/components/wechatImageText/index.vue';
import wechatImage from '@/components/wechatImageText/wechatImageText.vue';
import wechatEditor from '@/components/wechatEditor/wechatEditor.vue';
import openDialog from '@/components/wechatEditor/openDialog.vue';
import searchPerson from '@/components/groupManage/searchPerson.vue';

@Component({
  components: {
    VueUeditorWrap,
    wechatImageText,
    wechatEditor,
    openDialog,
    wechatImage,
    searchPerson
  }
})
export default class creatMessage extends Vue {
  public labelPosition: String = 'top';
  public showvisible: Boolean = false;
  public groupArr: any = [];
  public form: any = {
    sendType: 'toAll',
    msgType: 'text'
  };
  public formCopy: any = {
    aid: '',
    aGid: '',
    sGid: '',
    sid: ''
  };
  public queryParams: any = { //查询参数 
    agentId: '',
    total: 0
  };
  public querySend: any = {
    channelId: ''
  }
  public rules: any = {
    sid: [
      { required: true, message: '请选择消息通道', trigger: ['change'] }],
    aid: [
      { required: true, message: '请选择专栏', trigger: ['blur', 'change'] }],
    msgType: [
      { required: true, message: '消息类型', trigger: ['blur', 'change'] }],
    sendTypeOne: [
      { required: true, message: '请选择可见范围', trigger: ['blur', 'change'] }],
    timedSendDate: [
      { required: true, message: '请选择时间', trigger: ['blur'] }],

  }
  public dialogFormVisible: boolean = false;//是否显示弹框
  public PerdialogFormVisible: boolean = false;//是否显示人员查询弹框
  public readonly: boolean = true;
  public title: String = "选择图文素材";
  public content: String = "";//用来存储表单文字信息
  public loading: boolean = false;
  private applications: any[] = []; //存储专栏
  private imageList: any[] = []; //存储图文集合
  private sendMessageList: any[] = []; //存储群发消息分组
  public tableData: any[] = [];
  public passwayArr: any[] = [];//消息通道
  public ishowImage: Boolean = false;//默认是没有选中数据tableData==0 toallShow
  public toallShow: Boolean = true;//默认可以全选
  public prewType: string = '新增';//默认预览名单是新增
  public viewUser: string = '';//默认预览名单输入框内容
  public pickerOptions: any = {
    disabledDate: (time: { getTime: () => number; }) => {
      return time.getTime() < Date.now() - 1 * 24 * 3600 * 1000
    },
    // 禁用日期
    // selectableRange: `${Date().split(" ")[4]} - 23:59:59`, // 打开默认当前时间
  };//默认可以全选
  public userIdArr: string = '';//默认预览名单输入框内容
  public totalNum: number = 0;//总个数
  public noNum: number = 0;//无效个数
  public useNum: number = 0;//有效个数
  public noNumshow: Boolean = false;
  public previewClick: Boolean = false;//记录 是预览还是正文发送
  @Inject(I18nService) private i18nSvc!: I18nService;
  get i18n(): Dict<any> {
    return this.i18nSvc.i18nData;
  }
  @Inject(massSet) private massSet!: massSet;
  @Inject(WechatImageTextService) private wechatImageTextService!: WechatImageTextService;
  @Inject(WechatSendMessageGroupService) private wechatSendMessageGroupService!: WechatSendMessageGroupService;

  public async created(): Promise<void> {
    this.openFullScreen();
    //查询专栏
    await this.applicationsList();
    // 获取通道
    await this.getPassway();


  }
  //获取专栏
  public async applicationsList(): Promise<void> {
    const res = await this.wechatImageTextService.applicationsList();
    this.applications = res;
    if (this.applications.length > 0) {//默认选中一个专栏
      this.formCopy.aid = this.applications[0].id;
      localStorage.setItem('acceptChannelId', this.formCopy.aid)
      this.agentChanged(this.formCopy.aid)
    }
  }
  // 获取通道
  public async getPassway(): Promise<void> {
    const res = await this.massSet.getPassageway();
    this.passwayArr = res;
    console.log('224res', res)
    this.formCopy.sid = this.passwayArr[0].id;
  }

  //获取指定分组
  public async sendMessageListAll(channelId: any): Promise<void> {
    this.querySend.channelId = channelId;
    const res = await this.wechatSendMessageGroupService.sendMessageListAll(this.querySend);
    this.sendMessageList = res;
    console.log('231res', res)
  }

  //得到子组件传过来的值 
  public async getChild(val: any): Promise<void> {
    this.groupArr = [];//已选择分组清空
    this.tableData = [];
    this.tableData.push(val);
    this.querySend.channelId = val.channelId
    this.form.pubArticleId = this.tableData[0].id;
    if (this.tableData[0].toall == 1) {//目前只有两个 1 2 区分
      // 全体
      this.toallShow = true;
      const res = await this.wechatSendMessageGroupService.sendMessageListAll(this.querySend);
      this.sendMessageList = res;
    } else {
      this.form.sendTypeOne = 'toUsers'
      // 特定分组人员
      this.toallShow = false;
      const res = await this.wechatSendMessageGroupService.sendMessageListAll(this.querySend);
      this.sendMessageList = res;
      console.log('this.tableData[0].groupIds', this.tableData[0].groupIds, this.tableData[0].groupIds != '')
      if (this.tableData[0].groupIds != '' || this.tableData[0].groupIds != null || this.tableData[0].groupIds != undefined) {
        var arrya_ = this.tableData[0].groupIds.split(",");
        var sendcopy_ = this.sendMessageList;
        this.sendMessageList = [];
        if (sendcopy_.length > 0) {
          for (var i = 0; i < sendcopy_.length; i++) {
            for (var j = 0; j < arrya_.length; j++) {
              if (sendcopy_[i].id == arrya_[j]) {
                this.sendMessageList.push(sendcopy_[i])

              }
            }
          }
        }
      }
    }

    this.ishowImage = true
  }
  //二次触发点击事件
  public ishow() {
    let child = (this.$refs.childImage as wechatImageText)
    child.checkCont()
    child.types = '1'
  }


  //所属应用点击
  public async agentChanged(val: any) {
    this.queryParams.agentId = val;
    this.querySend.channelId = val;
    localStorage.setItem('acceptChannelId', val)
    this.clear();
    this.tableData = []
    this.ishowImage = false;
    this.showvisible = false;
    //查询指定分组
    await this.sendMessageListAll(this.querySend.channelId)
  }
  //点击发送
  public async submitForm(formName: any): Promise<void> {
    this.form.msgCategory = 'official';
    this.form.timedSend = 1;
    this.form.sendItem = this.userIdArr;
    this.sendForm();
  }
  //返回
  public async handleGoBack(): Promise<void> {
    this.$router.go(-1)
  }
  public async handleClick(tab: any, event: any): Promise<void> {
    this.clear();
    // 先清空再重新获取默认显示内容 content
    this.agentChanged(this.formCopy.aid)
    this.form.msgType = tab.name;
    if (this.form.msgType == 'news') {
      this.showvisible = true;
    }else{
      this.sendMessageListAll(this.queryParams.agentId)
    }
  }
  //清空form
  public clear() {
    this.form = {
      sendTypeOne: 'toAll',
      msgType: 'text',
      aid: this.formCopy.aid,
      sid: this.formCopy.sid
    }
    this.tableData = [];
    this.ishowImage = false;
    this.toallShow = true;
    this.userIdArr = '';//默认预览名单输入框内容
    this.totalNum = 0;//总个数
    this.noNum = 0;//无效个数
    this.useNum = 0;//有效个数
    this.noNumshow = false;
  }
  //获取指定预览人ud
  public async getListAll(): Promise<void> {
    this.dialogFormVisible = true;
    let param = {
      acceptChannelId: this.querySend.channelId,
    }
    const res = await this.massSet.getRoster(param);
    // 判断res【0】有没有sendItem的字段 有说明是更新 如果没有是新增
    if (res.length > 0) {
      if (res[0].id) {
        this.prewType = "修改"
        this.viewUser = res[0].sendItem
      } else {
        // 无内容
        this.prewType = "新增"
        this.viewUser = ''
      }
    } else {
      // 无内容
      this.prewType = "新增"
      this.viewUser = ''
    }
  }
  // 发送预览信息给预览人  点击预览的确定
  public async sendView(): Promise<void> {
    this.previewClick = true;
    this.form.msgCategory = 'preview';
    this.form.timedSend = 0;
    // this.form.sendTypeOne = 'toUsers';
    this.form.sendItem = this.viewUser;
    this.sendForm();

  }
  //点击发送
  public async subPop(form: any): Promise<void> {
    var that = this, Fanwei = '全体用户';
    this.form.sendItem = this.userIdArr;
    this.form.sendMessagesGroupSetId = this.groupArr;
    // 判断是否选择了通道
    if (this.formCopy.sid == '' || this.formCopy.sid == undefined) {
      this.$message({
        message: '请选择消息通道',
        type: 'error'
      });
      return;
    };
    // d判断所属专栏
    if (this.formCopy.aid == '') {
      this.$message({
        message: '请选择所属专栏',
        type: 'error'
      });
      return;
    };
    // 判断类型
    if (this.form.msgType == 'text') {
      if (this.content == undefined || this.content == '') {
        this.$message({
          message: '请输入文字',
          type: 'error'
        });
        return;
      } else {
        for (var i = 0; i < this.applications.length; i++) {//默认选中一个专栏
          if (this.applications[i].id == this.querySend.channelId) {
            this.form.content = '【' + this.applications[i].name + '】' + this.content;
          }
        }
      }
    } else if (this.form.msgType == 'news') {
      if (!this.form.pubArticleId) {
        this.$message({
          message: '请选择图文',
          type: 'error'
        });
        return;
      }
    }
    // 判断分组
    if (this.form.sendTypeOne == '') {
      this.$message({
        message: '请选择分组',
        type: 'error'
      });
      return;
    } else if (this.form.sendTypeOne == 'toUsers') {
      Fanwei = '指定用户'
      if (this.form.sendItem == '' || !this.form.sendItem) {
        this.$message({
          message: '请输入用户邮箱',
          type: 'error'
        });
        return;
      }
    } else if (this.form.sendTypeOne == 'toGroup') {
      Fanwei = '指定分组'
      if (this.form.sendMessagesGroupSetId == '' || !this.form.sendMessagesGroupSetId) {
        this.$message({
          message: '请选择分组',
          type: 'error'
        });
        return;
      }
    }
    // 判断时间
    if (!this.form.timedSendDate) {
      this.$message({
        message: '请选择正确的时间',
        type: 'error'
      });
      return;
    };
    this.checkPerson()
    // 确认要在【定时发送时间/立即】正式发布此消息，【可见范围选项】可见吗
    this.$confirm('确认要在' + '【 ' + this.form.timedSendDate + ' 】' + '正式发布此消息，' + '【' + Fanwei + '】' + '可见吗？', {
      confirmButtonText: '确定',
      cancelButtonText: '取消',
      type: 'info'
    }).then(() => {
      that.submitForm(form);
    }).catch(() => {

    });
  }
  // 发送
  public async sendForm(): Promise<void> {
    var send = '';
    var agent = '';
    // 判断是否选择了通道
    if (this.formCopy.sid == '' || this.formCopy.sid == undefined) {
      this.$message({
        message: '请选择消息通道',
        type: 'error'
      });
      return;
    };
    // d判断所属专栏
    if (this.formCopy.aid == '') {
      this.$message({
        message: '请选择所属专栏',
        type: 'error'
      });
      return;
    };
    // 判断类型
    if (this.form.msgType == 'text') {
      if (this.content == undefined) {
        this.$message({
          message: '请输入文字',
          type: 'error'
        });
        return;
      } else {
        for (var i = 0; i < this.applications.length; i++) {//默认选中一个专栏
          if (this.applications[i].id == this.querySend.channelId) {
            this.form.content = '【' + this.applications[i].name + '】' + this.content;
          }
        }
      }
    } else if (this.form.msgType == 'news') {
      if (!this.form.pubArticleId) {
        this.$message({
          message: '请选择图文',
          type: 'error'
        });
        return;
      }
    }
    // 判断分组
    if (!this.previewClick) {
      if (this.form.sendTypeOne == '') {
        this.$message({
          message: '请选择分组',
          type: 'error'
        });
        return;
      } else if (this.form.sendTypeOne == 'toUsers') {
        if (this.form.sendItem == '' || !this.form.sendItem) {
          this.$message({
            message: '请输入指定userId',
            type: 'error'
          });
          return;
        }
      } else if (this.form.sendTypeOne == 'toGroup') {
        if (this.form.sendMessagesGroupSetId == '' || !this.form.sendMessagesGroupSetId) {
          this.$message({
            message: '请选择分组',
            type: 'error'
          });
          return;
        }
      }
    }
    // 判断时间
    if (this.form.timedSend == 1) {
      if (!this.form.timedSendDate) {
        this.$message({
          message: '请选择正确的时间',
          type: 'error'
        });
        return;
      };
    }
    if (this.form.sendMessagesGroupSetId) {
      this.form.sendMessagesGroupSetId = this.form.sendMessagesGroupSetId.toString();
    }
    // 专栏id获取
    for (var i = 0; i < this.applications.length; i++) {
      if (this.applications[i].id == this.formCopy.aid) {
        this.formCopy.aGid = Number(this.applications[i].channelAgentId);

      }
    }
    // 消息通道id获取
    for (var i = 0; i < this.passwayArr.length; i++) {
      if (this.passwayArr[i].id == this.formCopy.sid) {
        this.formCopy.sGid = Number(this.passwayArr[i].channelAgentId);
      }
    }
    if (this.form.sendTypeOne == 'toUsers') {
      if (this.noNum > 0) {
        return;
      }
    }
    this.form.sendType = this.form.sendTypeOne;
    this.form.acceptChannelId = this.formCopy.sid;
    this.form.acceptChannelAgentId = this.formCopy.sGid;
    this.form.sendChannelId = this.formCopy.aid;
    this.form.sendChannelAgentId = this.formCopy.aGid;
    const res = await this.massSet.channelSend(this.form);
    if (this.previewClick) {
      this.previewClick = false
    }
    if (this.form.msgCategory != 'preview') {
      this.$router.push("/groupMessagesChannel");
    } else {
      this.dialogFormVisible = false;
      // this.form.sendItem = '';
    }
    if (res) {
      this.$message({
        message: '成功',
        type: 'success'
      });
    } else {
      this.$message({
        message: res.message,
        type: 'error'
      });

    }


  }
  // 打开人员搜索弹窗
  public openSearch() {
    this.PerdialogFormVisible = true;
  }
  //得到子组件传过来的值
  @Emit('close')
  public close(val: any): any {
    var strLength = [], Arr_ = [];
    if ( this.userIdArr == '') {
      this.userIdArr += val.toString();

    } else {
      this.userIdArr =  this.userIdArr + ',' + val.toString();
    }
    strLength =  this.userIdArr.split(',');
    Arr_ = Array.from(new Set(strLength));
    this.userIdArr = Arr_.toString();
    this.totalNum = Arr_.length;
    // this.userIdArr = val.toString();
    // strLength = this.userIdArr.split(',')
    // this.totalNum = strLength.length;
    this.PerdialogFormVisible = false;
  }
  // 键入textarea
  public userListStrWrite(e: any) {
    var strLength = [], Arr_ = [];
    this.noNumshow = false;
    if (this.userIdArr == '' || this.userIdArr == null || this.userIdArr == undefined) {
      this.totalNum = 0;

    } else {
      strLength = this.userIdArr.split(',');
      Arr_ = Array.from(new Set(strLength));
      this.userIdArr = Arr_.toString();
      this.totalNum = Arr_.length;
    }
  }
  // 检测用户
  public async checkPerson(): Promise<void> {
    var data_ = {};
    data_ = {
      "usersStr": this.userIdArr,
    }
    let res: any = await this.massSet.persnSearch(data_);
    if (res) {
      this.noNum = res.unFinds.length;
      this.useNum = res.empIds.length + res.emails.length;
      this.totalNum = res.unFinds.length + res.empIds.length + res.emails.length;
      this.noNumshow = true;
      this.form.userListStr = res.usersStr;
      if (res.unFinds.length > 0) {
        this.$alert('用户：' + res.unFinds.toString() + ' 不存在', '错误用户', {
          confirmButtonText: '确定',
          callback: action => {

          }
        });
      } else {
        this.$message({
          type: 'info',
          message: '用户检测完成'
        });
      }
    }

  }
  openFullScreen() {
    const loading = this.$loading({
      lock: true,
      text: 'Loading',
      spinner: 'el-icon-loading',
      background: 'rgba(0, 0, 0, 0.7)'
    });
    setTimeout(() => {
      loading.close();
    }, 1500);
  }

  /**
   * -END- CALL SERVICE
   */
}

function param(param: any) {
  throw new Error('Function not implemented.');
}

function typeOf(content: String): any {
  throw new Error('Function not implemented.');
}
